import React, { CSSProperties, useEffect } from "react";
import {
    Csku,
    useWindowSize,
} from "@commonsku/styles";
import { ReportProvider, useReportContext } from "./report_context";
import ReportTable from "./table/ReportTable";
import Pagination from "./table/Pagination";
import ActionBar from "./table/ActionBar";
import { getReportConfig } from "./config";
import Loading from "./Loading";
import styled from "styled-components";

const actionBarStyle: CSSProperties = {
    width: "auto",
    left: "176px",
    right: "0px",
};

const ReportContainer = styled(Csku)`
    position: relative;
`;

const StandaloneReport = ({ loadInitially }) => {
    const { fetchReport, firstLoaded } = useReportContext();
    useEffect(() => {
        if (loadInitially && !firstLoaded) {
            fetchReport();
        }
    }, [fetchReport, firstLoaded, loadInitially]);
    return (
        <>
            <ReportContainer as="div" className="csku-styles">
                <ReportTable />
                <Pagination />
                <Loading />
            </ReportContainer>
            <ActionBar style={actionBarStyle} />
        </>
    );
};
const StandaloneReportContainer = ({ reportType, loadInitially }) => {
    const windowHeight = useWindowSize()[1];
    const config = getReportConfig(reportType);
    console.log('loadInitially', reportType, loadInitially);
    return (
        <ReportProvider
            reportConfig={config}
            defaultFilters={null}
            containerHeight={windowHeight}
        >
            <StandaloneReport loadInitially={loadInitially} />
        </ReportProvider>
    );
};

export default StandaloneReportContainer;
