import React, { useMemo, useState } from "react";
import { useReportContext } from "../report_context";
import BulkActionBar from "../../BulkActionBar";

function ActionBar({ style }: { style?: React.CSSProperties }) {
  const { firstLoaded, totalRows, selectedRows, loading, reportConfig: { withSelection, bulkActions } } = useReportContext();  
  if (!withSelection || !bulkActions || !firstLoaded || loading || totalRows === 0) {
    return null;
  }
  return (
    <BulkActionBar
      selected={selectedRows}
      prepareLabel={bulkActions.prepareLabel}
      actions={bulkActions.actions}
      style={style}
    />
  );
}

export default ActionBar;
